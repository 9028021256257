<template>
    <div>
        <!-- <h2 class="title">Survey Year</h2>
        <b-button variant="outline-primary" v-b-modal.AddSurveyYearDetails>
            <b-icon icon="plus-circle-fill"  scale="1" variant="success"></b-icon>
            Add Survey Year
        </b-button>  -->
         <v-btn   color="primary" class="ma-2 white--text"  @click="addSurveyYearDialog = true">
            {{ $t("surveyYear.add") }} <v-icon right dark> mdi-plus</v-icon>
        </v-btn>
         <v-container color="primary">
              <v-card
                    icon="clipboard-text"
                    title="Survey year"
                    class="px-5 py-10">
                    <v-card-title>
                        <v-row>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details>
                            </v-text-field>
                        </v-row>
                        <v-row>
                            <v-progress-linear
                                :active="loading"
                                color="green"
                                :indeterminate="loading"
                                absolute
                                bottom
                                height="15">
                                Loading...
                            </v-progress-linear>`
                        </v-row>
                    </v-card-title>
                    <v-data-table
                        dense
                        :headers="fields"
                        :items="items"
                        :search="search"
                        class="elevation-1">
                         <template  v-slot:[`header.year`]="{ header }">
                                <h6><b> {{ header.text}}</b> </h6>
                        </template>
                         <template  v-slot:[`header.startDate`]="{ header }">
                                <h6><b> {{ header.text}}</b> </h6>
                        </template>
                         <template  v-slot:[`header.endDate`]="{ header }">
                                <h6><b> {{ header.text}}</b> </h6>
                        </template>
                         <template  v-slot:[`header.isActive`]="{ header }">
                                <h6><b> {{ header.text}}</b> </h6>
                        </template>
                         <template  v-slot:[`header.Action`]="{ header }">
                                <h6><b> {{ header.text}}</b> </h6>
                        </template>
                        <template  v-slot:[`item.startDate`]="{ item }">
                               {{ getDate(new Date(item.startDate))  }}
                        </template>
                        <template  v-slot:[`item.endDate`]="{ item }">
                                 {{ getDate(new Date(item.endDate))  }}
                        </template>
                        <template  v-slot:[`item.isActive`]="{ item}">
                                <v-checkbox
                                    v-model="item.isActive"
                                    @change="checkDialog(item) "
                                    hide-details >
                                </v-checkbox>
                        </template>

                    </v-data-table>
                </v-card>
         </v-container>
          <v-dialog v-model="confirmDialog" max-width="500px">
                <v-card
                :loading = loading loading-text="Loading... Please wait">
                    <v-card-title class="text-h3">Are you sure ? </v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="ActivateYear">Yes</v-btn>
                    <v-btn color="green darken-1" text @click="revertChange">No</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
               <v-dialog
                v-model="addBusinessSectorDialog"
                max-width="800px">
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">Add Survey Year</span>
                        </v-card-title>
                        <v-card-text>
                            <v-form ref="Addform" v-model="valid" lazy-validation>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="6" md="6">

                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-dialog>
         <!-- <b-table
        striped hover
        :items="items"
        :fields="fields"
        ref="SurveyYearsList"
        v-if="items.length > 0">
         <template #cell(startDate)="data">
            {{getDate(new Date(data.value))}}
        </template>
         <template #cell(endDate)="data">
            {{getDate(new Date(data.value))}}
        </template>
        <template #cell(isActive)="data">
            <b-form-checkbox  @change=ActivateYear(data.item,data.index) name="check-button" v-model="data.value" >
            </b-form-checkbox>
        </template>
        <template #cell(Action) ="row">
            <b-button size="sm" class="mr-2" @click="editSurveyYear(row.item,row.index)">Edit</b-button>
        </template>
        </b-table>
         <div v-if="items.length ==0">
            <center>
                <h2>No Items</h2>
            </center>
        </div>

         <b-modal id="AddSurveyYearDetails" title="Add Survey Year" hide-footer @show="onReset()" hide-backdrop>
            <form ref="form" @submit.stop.prevent="addSurveyYear">
            <b-form-group
            label="Survey Year"
            :validator="$v.form.surveyYear"
            :messages="{ required:'this is a required fieeeld', yearExists:'this year already exists'}"
            label-for="year">
                <b-form-input
                    type="number"
                    id="year"
                    placeholder="Enter Survey Year"
                    v-model="$v.form.surveyYear.$model"
                    :state="validateState('surveyYear')"
                    aria-describedby="surveyYear-feedback"
                    >
                </b-form-input>
                 <b-form-invalid-feedback id="surveyYear-feedback">
                        This is a required field.
                    </b-form-invalid-feedback>
            </b-form-group>
             <b-button type="submit" variant="primary">Submit</b-button>
            <b-button class="ml-2" @click="onReset()">Reset</b-button>
            </form>
         </b-modal>
          <b-modal id="EditSurveyYearModal" title="Edit Survey Year" hide-footer hide-backdrop>
               <form ref="form" @submit.stop.prevent="updateSurveyYear">
            <b-form-group
            label="Survey Year"
            label-for="year">
                <b-form-input
                    type="number"
                    id="year"
                    placeholder="Enter Survey Year"
                    v-model="$v.form.surveyYear.$model"
                    :state="validateState('surveyYear')"
                    aria-describedby="surveyYear-feedback">
                </b-form-input>
                 <b-form-invalid-feedback id="surveyYear-feedback">
                        This is a required field.
                    </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
            label="Start Date"
            label-for="StartDate"
            invalid-feedback="Invalid Date" >
                <b-form-input
                    type="date"
                    placeholder="select start date"
                    id="StartDate"
                    v-model="$v.form.startDate.$model"
                    :state="validateState('startDate')"
                    aria-describedby="startDate-feedback">
                </b-form-input>
                <b-form-invalid-feedback id="startDate-feedback">
                        This is a required field and should be less than end date
                </b-form-invalid-feedback>
            </b-form-group>
             <b-form-group
            label="End Date"
            label-for="EndDate"
            invalid-feedback="Invalid Date" >
                <b-form-input
                    type="date"
                     placeholder="select end date"
                    id="EndDate"
                    v-model="$v.form.endDate.$model"
                    :state="validateState('endDate')"
                    aria-describedby="endDate-feedback">
                </b-form-input>
                <b-form-invalid-feedback id="endDate-feedback">
                        This is a required field and should be greater than start date and should be 1 year
                </b-form-invalid-feedback>
            </b-form-group>
             <b-button type="submit" variant="primary">Update</b-button>
            <b-button class="ml-2" @click="onReset()">Reset</b-button>
               </form>
          </b-modal>   -->

    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required,between} from "vuelidate/lib/validators";
export default {
    mixins: [validationMixin],
     computed: {
            startDate: function () {
            return this.getDate( new Date(this.form.year, 0, 1));
            },
            endDate: function () {
            return new Date(this.form.year, 11, 31);
            },
    },
    data() {
      return {
        addSurveyYearDialog:false,
        loading:false,
        confirmDialog:false,
        search:'',

        form: {
            surveyYear:null,
            startDate:null,
            endDate:null
        },
        fields: [
                {value: 'year', text: this.$t("surveyYear.grid.year") },
                {value: 'startDate', text: this.$t("surveyYear.grid.startDate") },
                {value: 'endDate', text: this.$t("surveyYear.grid.endDate") },
                {value: 'isActive', text: this.$t("surveyYear.grid.isActive") },
                {value: 'Action', text: this.$t("surveyYear.grid.Action") }
                ],
        items: [],
         currentEditedYear:null
      }
    },
    validations: {
        form: {
            surveyYear: {
                required,
                between:between(2010,2030),
                yearExists:{
                    check(){
                        if(this.items.filter(i=>i.year == this.form.surveyYear).length > 0)
                        {
                            return false;
                        }
                        else
                        {
                            return true;
                        }
                    }
                }
            },
            startDate:{
                required,
            },
            endDate:{
                required,
                toDateValidator:{
                    check(){
                            var date1 = new Date(this.form.startDate);
                            var date2 = new Date(this.form.endDate);
                            var diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);

                            if(diffDays >= 365) {
                                return true;
                            }
                            else{
                                return false;
                            }

                    }
                }
            }
        }
    },
    methods:{
        checkDialog(item){
            this.editedItem = item;
            this.editedIndex = this.items.indexOf(item)
            this.confirmDialog = true;
        },
        revertChange(){
            this.editedItem.isActive = ! this.editedItem.isActive
            this.confirmDialog = false;
        },
        calculatePeriod(val){
            console.log(val);
        },
        getDate(date){

          let today = new Date();
          if(date != null){
            today = date;
          }
          var dd = String(today.getDate()).padStart(2, '0');
          var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
          var yyyy = today.getFullYear();
          return  dd + '/' + mm + '/' + yyyy;
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        onReset(){
            this.form ={
                surveyYear: null,
                startDate:null,
                endDate:null
            };
             this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        addSurveyYear(){
             this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            this.$axios({
                    method: 'post',
                    url: `${this.$baseURL}/SurveyYear`,
                    data: {
                        Year:  Number(this.form.surveyYear),
                        StartDate: this.form.startDate,
                        EndDate:this.form.endDate,
                    }
                }).then((response)=>{

                    this.$nextTick(() => {
                        this.$bvModal.hide('AddSurveyYearDetails')
                    });

                    this.$bvModal.msgBoxOk('Survey Year added successfully', {
                                            title: 'Confirmation',
                                            size: 'sm',
                                            buttonSize: 'sm',
                                            okVariant: 'success',
                                            headerClass: 'p-2 border-bottom-0',
                                            footerClass: 'p-2 border-top-0',
                                            centered: true
                                            });
                    this.items = response.data;

                });
        },
        deleteSurveyYear(item){
             this.$bvModal.msgBoxConfirm('Are you sure?')
             .then(value => {
                if(value == true){
                   this.$axios({
                    method: 'delete',
                    url: `${this.$baseURL}/SurveyYear`,
                    data: {Id:item.year}
                    }).then((result)=>{
                        this.items = result.data
                    });
                }
            });
        },
        ActivateYear(){


            this.$axios({
                    method: 'put',
                    url: `${this.$baseURL}/SurveyYear`,
                    data: {
                            Year:  Number(this.editedItem.year),
                            IsActive:true
                    }
                    }).then((result)=>{
                        if(result.data == true){
                             for(var i in this.items){
                                if(i!=this.editedIndex){
                                    this.items[i].isActive = false;
                                    }
                                }

                                this.$store.dispatch("updateSurveyYear",this.items[this.editedIndex].year);
                                this.items[this.editedIndex].isActive = true;
                               this.confirmDialog = false;

                            }

                    });



        },
        editSurveyYear(item,index){
            this.$nextTick(() => {
                this.form.surveyYear = item.year;
                this.form.startDate = item.startDate;
                this.form.endDate = item.endDate;
                this.$bvModal.show('EditSurveyYearModal');
                this.currentEditedYear = index;
            });
        },
        updateSurveyYear(){
             this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            this.$axios({
                    method: 'put',
                    url: `${this.$baseURL}/SurveyYear`,
                    data: {
                            Year:  Number(this.form.surveyYear),
                            StartDate: this.form.startDate,
                            EndDate:this.form.endDate,
                    }
                }).then((response)=>{
                    console.log(response)
                    if(response.data == true)
                    {
                        this.items[this.currentEditedSector] = this.form;
                        this.$bvModal.hide('EditSurveyYearModal');
                        this.$refs.SurveyYearsList.refresh();
                    }
                });
        },
    },
    mounted(){
        this.$axios({
                method: 'get',
                url: `${this.$baseURL}/SurveyYear`,
            })
            .then((response)=>{
                this.items = response.data;
                console.log(this.items);
            });
    }
}
</script>

<style scoped >

</style>